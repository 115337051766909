import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import { api } from "../../config";
import FileUpload from "../Misc/FormComponents/FileUpload";
import Loading from "../Misc/Loading";
import ExtraFormStyles from "./styles/extraFormStyles";

const schema = yup.object({
  image: yup.string().required("Image is required"),
});
interface Props {}

const ExtraForm: React.FC<Props> = () => {
  const classes = ExtraFormStyles();

  const [loading, setLoading] = React.useState(true);
  const [image, setImage] = React.useState("");

  const handleSubmit = async ({ image }: any) => {
    const data = {
      image: image,
    };

    try {
      const output = await axios.put(`${api}/homebanner`, data, {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      const output = await axios.get(`${api}/homebanner`, {
        withCredentials: true,
      });
      setImage(output.data.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);
  if (loading) return <Loading />;

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                image: image,
              }}
              validationSchema={schema}
            >
              {({ values, errors, isValid, setFieldValue }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <h3 className={classes.heading}>Update Home Banner</h3>

                  <img
                    src={`${api}${values.image}`}
                    alt="img"
                    style={{ height: "100px", width: "100px" }}
                  />
                  <FileUpload
                    text="Upload"
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={`image`}
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ExtraForm;

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PopMessage from "./components/Misc/PopMessage";
import { api, cloudApi } from "./config";
import "./index.css";
// import * as serviceWorker from "./serviceWorker";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.NODE_ENV === "development" ? api : cloudApi, //"http://localhost:4000/graphql",
    credentials: "include",
  }),
  resolvers: {},
});

client.writeData({
  data: {
    popMsg: {
      __typename: "PopMessage",
      isMsgOpen: false,
      message: "",
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
    <PopMessage />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

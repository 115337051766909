import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

import StorageIcon from "@material-ui/icons/Storage";
import CreateIcon from "@material-ui/icons/Create";

import Card from "../Template/Card/Card";
import CardBody from "../Template/Card/CardBody";
import shortCutStyles from "./styles/shortCutStyles";

const useStyles = shortCutStyles;

const ShortCuts = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/admin/topics">
        <Card shortcut>
          <CardBody>
            <div className={classes.cardBody}>
              <StorageIcon color="primary" />
              <Typography variant="button">View Topics</Typography>
            </div>
          </CardBody>
        </Card>
      </Link>
      <Link to="/admin/quizzes-form">
        <Card shortcut>
          <CardBody>
            <div className={classes.cardBody}>
              <CreateIcon color="primary" />
              <Typography variant="button">Add Quizzes</Typography>
            </div>
          </CardBody>
        </Card>
      </Link>
    </div>
  );
};

export default ShortCuts;

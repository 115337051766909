import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";
import TextFormField from "../Misc/FormComponents/TextFormField";
import quizFormStyles from "./styles/quizFormStyles";

const schema = yup.object({
  quizNo: yup.string().required("Quiz No is required"),
  question: yup.string().required("Question is required"),
  explanation: yup.string().required("Explanation is required"),
});

type Props = {
  quiz?: any;
  isDialog?: React.Dispatch<React.SetStateAction<boolean>>;
};

type SubmitType = {
  quizNo: string;
  question: string;
  explanation: string;
  isTrue: boolean;
  image: string;
};

const DiffQuestionForm: React.FC<Props> = ({ quiz, isDialog }) => {
  const history = useHistory();
  const classes = quizFormStyles();

  const handleSubmit = async ({
    quizNo,
    question,
    explanation,
    isTrue,
    image,
  }: SubmitType) => {
    const data = {
      quizNo: parseInt(quizNo),
      question: question,
      explanation: explanation,
      isTrue: isTrue,
      image: image,
    };

    try {
      if (quiz) {
        await axios.put(`${api}/diffquestions/${quiz.id}`, data, {
          withCredentials: true,
        });
        history.push("/admin/difficult-quizzes");
      } else {
        await axios.post(`${api}/diffquestions`, data, {
          withCredentials: true,
        });
        history.push("/admin/difficult-quizzes");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                quizNo: quiz?.quizNo || "",
                question: quiz?.question || "",
                explanation: quiz?.explanation || "",
                isTrue: quiz?.isTrue || false,
                image: quiz?.image || "",
              }}
              validationSchema={schema}
            >
              {({ values, errors, isValid, setFieldValue }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <h3 className={classes.heading}>
                    {quiz ? "Update" : "Add"} Quiz
                  </h3>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 3fr",
                      gap: 4,
                    }}
                  >
                    <Field
                      label="Quiz No"
                      name="quizNo"
                      component={TextFormField}
                      variant="outlined"
                    />
                    <Field
                      label="Question"
                      name="question"
                      component={TextFormField}
                      variant="outlined"
                      multiline
                    />
                  </div>
                  <Field
                    label="Explanation"
                    name="explanation"
                    component={TextFormField}
                    variant="outlined"
                    multiline
                  />
                  <Field
                    label="True or False"
                    name="isTrue"
                    component={CheckboxFormField}
                    variant="outlined"
                    defaultChecked={values.isTrue}
                  />
                  {values.image && (
                    <img
                      src={`${api}${values.image}`}
                      alt="img"
                      style={{ height: "100px", width: "100px" }}
                    />
                  )}
                  <FileUpload
                    text="Upload"
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={`image`}
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default DiffQuestionForm;

import { Button, Typography } from "@material-ui/core";
import { Field, FieldArray } from "formik";
import React from "react";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";
import TextFormField from "../Misc/FormComponents/TextFormField";
import quizSubFormStyles from "./styles/quizSubFormStyles";

interface Props {
  quizzes: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  validateForm: any;
}

const QuizzesSubForm: React.FC<Props> = ({
  quizzes,
  setFieldValue,
  validateForm,
}) => {
  const classes = quizSubFormStyles();

  return (
    <FieldArray name="quizzes">
      {(arrayHelpers) => (
        <div>
          <Typography variant="button">Quizzes</Typography>
          {quizzes && quizzes.length > 0 ? (
            quizzes.map((quiz: string, index: number) => (
              <div className={classes.subForm} key={index}>
                <Field
                  label="Quiz No"
                  variant="outlined"
                  component={TextFormField}
                  // type="number"
                  name={`quizzes.${index}.qNo`}
                />
                <Field
                  label="Sinhala Question"
                  variant="outlined"
                  component={TextFormField}
                  // type="number"
                  name={`quizzes.${index}.sinQ`}
                  multiline
                />
                <Field
                  label="Itailan Question"
                  variant="outlined"
                  component={TextFormField}
                  // type="number"
                  name={`quizzes.${index}.itaQ`}
                  multiline
                />
                <Field
                  label="True Or False"
                  variant="outlined"
                  component={CheckboxFormField}
                  // type="number"
                  name={`quizzes.${index}.isTrue`}
                />
                <FileUpload
                  text="Upload"
                  handleSetFieldValue={setFieldValue}
                  handleFieldName={`quizzes.${index}.image`}
                />
                <Button
                  style={{
                    fontWeight: "bold",
                    width: "100px",
                    justifySelf: "flex-end",
                    // fontSize: "1.2rem"
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    arrayHelpers.remove(index);
                    setTimeout(() => validateForm(), 200);
                  }}
                >
                  -
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    fontWeight: "bold",
                    maxWidth: "100px",
                    // fontSize: "1.2rem"
                  }}
                  onClick={() =>
                    arrayHelpers.insert(index + 1, {
                      qNo: "",
                      sinQ: "",
                      itaQ: "",
                      isTrue: false,
                      image: "",
                    })
                  } // insert an empty string at a position
                >
                  +
                </Button>
              </div>
            ))
          ) : (
            <>
              <Button
                variant="contained"
                fullWidth
                onClick={() =>
                  arrayHelpers.push({
                    qNo: "",
                    sinQ: "",
                    itaQ: "",
                    isTrue: false,
                  })
                }
              >
                Add Quizzes
              </Button>
            </>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default QuizzesSubForm;

import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress, {
  CircularProgressProps
} from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    //@ts-ignore
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: spacing(2)
      },
      alignItems: "center",
      justifyContent: "center",
      //@ts-ignore
      height: ({ fullHeight }) => (fullHeight ? "80vh" : "100%"),
      padding: 30
    }
  })
);

interface Props extends CircularProgressProps {
  fullHeight?: boolean;
}

const Loading: React.FC<Props> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  return (
    //@ts-ignore
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  );
};

export default Loading;

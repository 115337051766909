import Button from "@material-ui/core/Button";
import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import DialogModal from "../components/Misc/DialogModal";
import GoBackButton from "../components/Misc/GoBackButton";
import QuizForm from "../components/Quiz/QuizForm";
import { api } from "../config";
// import { DELETE_QUIZ_MUTATION } from "./quizzes";

interface Props {}

interface RowData {
  id: string;
  sinQ: string;
  itaQ: string;
  isTrue: string;
  topicId: string;
  qNo: string;
  schedaNo: string;
}

const ViewAllTopicQuizzes: React.FC<Props> = () => {
  const matchBoth = useRouteMatch("/admin/all-topic-quizzes/:topicId/:qType");
  const matchTopic = useRouteMatch("/admin/all-topic-quizzes/:topicId");
  const [editQuiz, setEditQuiz] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const ref = React.useRef(null);

  // const [deletQuiz] = useMutation(DELETE_QUIZ_MUTATION);

  let topicId: any = undefined;
  let qType: any = undefined;

  const [quizzesData, setQuizzesData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getQuizzes = async () => {
    try {
      if (qType) {
        const response = await axios.get(
          `${api}/quizzes?topic=${topicId}&qType=${qType}&_sort=qNo:ASC&_limit=10000`,
          {
            withCredentials: true,
          }
        );
        setQuizzesData(response.data);
      } else {
        const response = await axios.get(
          `${api}/quizzes?topic=${topicId}&_sort=qNo:ASC&_limit=10000`,
          {
            withCredentials: true,
          }
        );
        setQuizzesData(response.data);
      }
      setLoading(false);
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (topicId) {
      getQuizzes();
    }
  }, [topicId, qType]);

  if (matchBoth) {
    //@ts-ignore
    topicId = matchBoth?.params.topicId;
    //@ts-ignore
    qType = matchBoth?.params.qType;
  } else if (matchTopic) {
    //@ts-ignore
    topicId = matchTopic?.params.topicId;
  }

  const handleAddClick = () => {
    history.push(`/admin/quizzes-form`);
  };

  const handleDeleteClick = async (id: number) => {
    if (window.confirm("Are you sure to delete this Question?")) {
      await axios.delete(`${api}/quizzes/${id}`, { withCredentials: true });
      getQuizzes();
    }
  };

  React.useEffect(() => {
    if (open === false && Object.keys(editQuiz).length > 0) {
      getQuizzes();
    }
  }, [open]);

  if (typeof topicId === "undefined" && typeof qType === "undefined") {
    console.log(topicId);
    console.log(qType);
    return <div>No Url Parameters</div>;
  }

  return (
    <div>
      {open && (
        <DialogModal
          title="Edit Quiz"
          content={<QuizForm quiz={editQuiz} isDialog={setOpen} />}
          open={open}
          setOpen={setOpen}
          width="lg"
        />
      )}
      <GoBackButton />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Quiz
      </Button>
      <br />
      <br />
      <MaterialTable
        tableRef={ref}
        title={`Topic => All quizzes`}
        columns={[
          {
            title: "True or False",
            field: "isTrue",
            filtering: false,
            render: (rowData: any) =>
              rowData.isTrue ? (
                <div
                  style={{
                    backgroundColor: "green",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  True
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  False
                </div>
              ),
          },
          { title: "Scheda No", field: "schedaNo" },
          { title: "Quiz No", field: "qNo" },
          { title: "Question in Italian", field: "itaQ" },
          { title: "Question in Sinhala", field: "sinQ" },
        ]}
        localization={{
          header: {
            actions: "",
          },
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Delete Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              handleDeleteClick(rowData.id);
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              const data = { ...rowData, topicId: rowData.topic.id };
              setEditQuiz(data);
              setOpen(true);
            },
          },
        ]}
        options={{
          actionsColumnIndex: 0,
          exportButton: true,
          filtering: true,
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40],
        }}
        data={quizzesData}
      />
    </div>
  );
};

export default ViewAllTopicQuizzes;

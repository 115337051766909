import { gql, useApolloClient } from "@apollo/client";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import Dropdown from "../Misc/FormComponents/Dropdown";
import FileUpload from "../Misc/FormComponents/FileUpload";
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField";
import TextFormField from "../Misc/FormComponents/TextFormField";
import Loading from "../Misc/Loading";
import { questionTypes } from "./QuizzesForm";
import quizFormStyles from "./styles/quizFormStyles";

yup.addMethod(
  yup.array,
  "unique",
  function (message: any, mapper = (a: any) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  }
);

const schema = yup.object({
  topicId: yup.string().required("Topic is required"),
  schedaNo: yup.string().required("Scheda No is required"),
  qNo: yup.string().required("Quiz No is required"),
  sinQ: yup.string().required("Sinhala Question is required"),
  itaQ: yup.string().required("Italian Question is required"),
  isTrue: yup.boolean(),
});

type TopicFieldProps = {
  topicIdNo?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  topics: any;
};
const TopicField: React.FC<TopicFieldProps> = ({
  topics,
  setFieldValue,
  topicIdNo,
}) => {
  React.useEffect(() => {
    if (topicIdNo) {
      handleTopicChange(topicIdNo, setFieldValue);
    }
  }, []);

  const handleTopicChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue("topicId", parseInt(value));
  };

  return (
    <Field
      label="Topics"
      name="topicId"
      component={ReactSelectFormField}
      options={topics}
      placeholder="Topics"
    />
  );
};

type Props = {
  quiz: any;
  isDialog?: React.Dispatch<React.SetStateAction<boolean>>;
};

type SubmitType = {
  topicId: string;
  schedaNo: string;
  qNo: string;
  qType: string;
  sinQ: string;
  itaQ: string;
  isTrue: boolean;
  image: string;
};

const QuizForm: React.FC<Props> = ({ quiz, isDialog }) => {
  const history = useHistory();
  const client = useApolloClient();
  const classes = quizFormStyles();

  const [loading, setLoading] = React.useState(true);
  const [topicsData, setTopicsData] = React.useState([]);
  const getTopics = async () => {
    const response = await axios.get(`${api}/topics`, {
      withCredentials: true,
    });
    setTopicsData(response.data);
    setLoading(false);
    console.log(response.data);
  };
  React.useEffect(() => {
    getTopics();
  }, []);

  const handleSubmit = async ({
    topicId,
    schedaNo,
    qNo,
    sinQ,
    itaQ,
    qType,
    isTrue,
    image,
  }: SubmitType) => {
    const data = {
      topicId: parseInt(topicId),
      schedaNo: parseInt(schedaNo),
      qNo: parseInt(qNo),
      qType,
      sinQ: sinQ,
      itaQ: itaQ,
      isTrue: isTrue,
      image: image,
    };

    try {
      const output = await axios.put(`${api}/quizzes/${quiz.id}`, data, {
        withCredentials: true,
      });
      isDialog && isDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  const topics = topicsData.map(
    (topic: { italian: string; __typename: string; id: number }) => ({
      label: topic.italian,
      value: topic.id,
    })
  );
  console.log(quiz);
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                topicId: quiz.topicId,
                schedaNo: quiz.schedaNo,
                qNo: quiz.qNo,
                qType: quiz.qType,
                sinQ: quiz.sinQ,
                itaQ: quiz.itaQ,
                isTrue: quiz.isTrue,
                image: quiz.image,
              }}
              validationSchema={schema}
            >
              {({ values, errors, isValid, setFieldValue }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <h3 className={classes.heading}>Update Quiz</h3>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 1fr",
                      alignItems: "center",
                      gridGap: 10,
                    }}
                  >
                    <TopicField
                      topics={topics}
                      topicIdNo={quiz.topicId}
                      setFieldValue={setFieldValue}
                    />
                    <Field
                      label="Scheda No"
                      name="schedaNo"
                      component={TextFormField}
                      variant="outlined"
                    />
                  </div>
                  <Field
                    label="Quiz No"
                    name="qNo"
                    component={TextFormField}
                    variant="outlined"
                  />
                  <Field
                    name="qType"
                    component={Dropdown}
                    items={questionTypes}
                  />
                  <Field
                    label="Question in Italian"
                    name="itaQ"
                    component={TextFormField}
                    variant="outlined"
                    multiline
                  />
                  <Field
                    label="Question in Sinhala"
                    name="sinQ"
                    component={TextFormField}
                    variant="outlined"
                    multiline
                  />
                  <Field
                    label="True or False"
                    name="isTrue"
                    component={CheckboxFormField}
                    variant="outlined"
                    defaultChecked={values.isTrue}
                  />
                  <img
                    src={`${api}${values.image}`}
                    alt="img"
                    style={{ height: "100px", width: "100px" }}
                  />
                  <FileUpload
                    text="Upload"
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={`image`}
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default QuizForm;

const UPDATE_QUIZ_MUTATION = gql`
  mutation UPDATE_QUIZ_MUTATION($data: QuizInput!) {
    updateQuiz(input: $data)
  }
`;

const GET_TOPICS_QUERY = gql`
  query GET_TOPICS_QUERY {
    getTopics {
      data {
        id
        italian
      }
    }
  }
`;

import { makeStyles, Theme, createStyles } from "@material-ui/core";

const quizSubFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    subForm: {
      display: "grid",
      gridGap: 10,
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"
      },

      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: ".2fr 1fr 1fr .2fr .1fr .1fr"
      }
    }
  })
);
export default quizSubFormStyles;

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
// import { checkAuth } from "./utils/ProtectedRoute";
import Login from "./pages/login";
import Home from "./pages/home";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import "./assets/css/material-dashboard-react.css?v=1.8.0";
// import Dashboard from "./components/dashboard";
// import ViewProducts from "./pages/products";
// import ProductForm from "./components/ProductForm";

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <ProtectedRoute path="/admin" component={Home} />
      <Route path="/login" exact component={Login} />
      {/* <ProtectedRoute path="/product/:id" component={ProductForm} /> */}
      {/* <ProtectedRoute path="/products" exact component={ViewProducts} /> */}
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </BrowserRouter>
);

export default App;

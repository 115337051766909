import Button from "@material-ui/core/Button";
import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import { api } from "../config";

interface Props {}

interface RowData {
  id: string;
  sinQ: string;
  itaQ: string;
  qNo: string;
  qType: string;
  isTrue: string;
  topicId: string;
  schedaNo: string;
}

const DifficultQuizzes: React.FC<Props> = () => {
  const history = useHistory();
  const ref = React.useRef(null);

  const getQuizzes = async ({ start = 0, limit = 10 }) => {
    const response = await axios.get(
      `${api}/diffquestions?_sort=quizNo:ASC&_start=${start}&_limit=${limit}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  };

  const getTotalCount = async ({ start = 0, limit = 10 }) => {
    const response = await axios.get(
      `${api}/diffquestions/count?_sort=quizNo:ASC&_start=${start}&_limit=${limit}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  };

  const handleAddClick = () => {
    history.push(`/admin/diffquestion-form`);
  };

  const handleDeleteClick = async (id: number) => {
    if (window.confirm("Are you sure to delete this Question?")) {
      await axios.delete(`${api}/diffquestions/${id}`, {
        withCredentials: true,
      });
      //@ts-ignore
      ref.current.onQueryChange();
    }
  };

  return (
    <div>
      <GoBackButton />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Quiz
      </Button>
      <br />
      <br />
      <MaterialTable
        tableRef={ref}
        title={`Difficult Questions`}
        columns={[
          {
            title: "True or False",
            field: "isTrue",
            filtering: false,
            render: (rowData: any) =>
              rowData.isTrue ? (
                <div
                  style={{
                    backgroundColor: "green",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  True
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  False
                </div>
              ),
          },
          { title: "Quiz No", field: "quizNo" },
          { title: "Question", field: "question" },
          { title: "Explanation", field: "explanation" },
        ]}
        localization={{
          header: {
            actions: "",
          },
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Delete Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              handleDeleteClick(rowData.id);
              // editQuestion(rowData.id);
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              history.push(`/admin/diffquestion-form/${rowData.id}`);
            },
          },
        ]}
        options={{
          actionsColumnIndex: 0,
          exportButton: true,
          filtering: false,
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40],
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            Promise.all([
              getQuizzes({
                start: query.page * query.pageSize,
                limit: query.pageSize,
              }),
              getTotalCount({
                start: query.page * query.pageSize,
                limit: query.pageSize,
              }),
            ]).then((result) => {
              console.log(result);
              resolve({
                data: result[0],
                page: query.page,
                totalCount: result[1],
              });
            });
          })
        }
      />
    </div>
  );
};

export default DifficultQuizzes;

import React from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      //   marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  })
);

interface Props {}

const GoBackButton: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />
        Go Back
      </Button>
    </div>
  );
};

export default GoBackButton;

import React from "react";
import ExtraForm from "../components/Extras/ExtraForm";
import GoBackButton from "../components/Misc/GoBackButton";
import GridContainer from "../components/Template/Grid/GridContainer";

interface Props {}

const Extras: React.FC<Props> = () => {
  return (
    <>
      <GoBackButton />

      <GridContainer>
        <ExtraForm />
      </GridContainer>
    </>
  );
};

export default Extras;

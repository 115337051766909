import { gql } from "@apollo/client";
import axios from "axios";
import React from "react";
import {} from "react-dom";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Loading from "../components/Misc/Loading";
import { api } from "../config";

type Props = {
  component: any;
};

export const ProtectedRoute: React.FC<RouteProps & Props> = ({
  component: Component,
  path,
  ...rest
}) => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  const getMyData = async () => {
    try {
      const userData = await axios.get(`${api}/users/me`, {
        withCredentials: true,
      });
      console.log(userData);
      setUser(userData.data);
    } catch (err) {}
    setLoading(false);
  };
  React.useEffect(() => {
    getMyData();
    // setLoading(false);
  }, []);
  // const { data, loading, error } = useQuery(ME_QUERY, {
  //   fetchPolicy: "network-only"
  // });
  if (loading) return <Loading fullHeight={true} />;
  // if (error) {
  //   console.log(error);
  //   return <div>Error</div>;
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== null) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
};

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      username
    }
  }
`;

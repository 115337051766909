import React from "react";
import ShortCuts from "./ShortCuts";

const Dashboard = () => {
  return (
    <div>
      <ShortCuts />
      {/* <Charts data={data.getSummary} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <Summary data={data} />
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <SalesViewDay />
        </GridItem>
      </GridContainer>
      <SalesViewMonth /> */}
      {/*
        <GridItem xs={12} sm={12} md={6}>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <ViewStocks />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <TopProducts topItems={data.getSummary.topItems} />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <TopSuppliers topSuppliers={data.getTopSuppliers} />
        </GridItem>
      </GridContainer> */}
    </div>
  );
};

export default Dashboard;

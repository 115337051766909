import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { FieldProps } from "formik";

interface Props extends CheckboxProps {
    label: string;
}

const CheckboxFormField: React.FC<FieldProps & Props> = React.memo(
    ({ form, field, label, ...props }) => {
        return (
            <FormControlLabel
                control={<Checkbox {...field} {...props} />}
                label={label}
            />
        );
    }
);

export default CheckboxFormField;

import { gql } from "@apollo/client";
import { Button } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import Card from "../components/Template/Card/Card";
import CardHeader from "../components/Template/Card/CardHeader";
import GridContainer from "../components/Template/Grid/GridContainer";
import GridItem from "../components/Template/Grid/GridItem";
import { api } from "../config";

interface Props {}

const ViewSchedas: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/schedas/:id/:qType");
  const { state } = useLocation();
  // console.log(state);
  const [loading, setLoading] = React.useState(true);
  //@ts-ignore
  const topicId = match?.params.id;
  //@ts-ignore
  const qType = match?.params.qType;

  const [schedasCount, setSchedasCount] = React.useState(0);
  const getSchedas = async () => {
    try {
      const response = await axios.get(
        `${api}/quizzes/scheda-count-topic-type?topic=${topicId}&qType=${qType}`,
        {
          withCredentials: true,
        }
      );
      setSchedasCount(response.data);
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      setSchedasCount(0);
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (topicId && qType) {
      getSchedas();
    }
  }, [topicId, qType]);

  if (loading) return <Loading />;

  if (schedasCount === 0)
    return (
      <>
        <GoBackButton />
        <h4>{state.topic}</h4>
        <h5 style={{ color: "red" }}>No Schedas</h5>
      </>
    );

  const sCount = qType === "PS" ? 61 : schedasCount;
  const sNo = (indexNo: number) =>
    qType === "PS" ? indexNo + 1001 : indexNo + 1;

  return (
    <>
      <GoBackButton />
      <Link to={{ pathname: `/admin/all-topic-quizzes/${topicId}/${qType}` }}>
        <Button color="primary">View All qustions</Button>
      </Link>
      <h4>{state ? state.topic : ""}</h4>
      <GridContainer>
        {Array(sCount)
          .fill(0)
          .map((_, index) => (
            <GridItem xs={12} sm={6} md={3} key={index + 1}>
              <Link
                to={{
                  pathname: `/admin/quizzes/${topicId}/${qType}/${sNo(index)}`,
                  state: {
                    topicId,
                    schedaNo: sNo(index),
                  },
                }}
              >
                <Card>
                  <CardHeader color="primary" stats icon>
                    <h3
                      style={{
                        fontSize: 50,
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      Scheda: {sNo(index)}
                    </h3>
                  </CardHeader>
                </Card>
              </Link>
            </GridItem>
          ))}
      </GridContainer>
    </>
  );
};

export default ViewSchedas;

export const GET_SCHEDAS_QUERY = gql`
  query GET_SCHEDAS_QUERY($topicId: Int!, $qType: String!) {
    getSchedas(topicId: $topicId, qType: $qType) {
      schedaNo
    }
  }
`;

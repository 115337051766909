import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1)
    },
    buttonProgress: {
      color: grey[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  })
);

interface Props extends ButtonProps {
  loading: boolean;
  btnText?: string;
}

const SubmitBtn: React.FC<Props> = ({
  loading = false,
  btnText = "Submit",
  ...props
}) => {
  const classes = useStyles({});
  return (
    <Button
      variant="contained"
      className={classes.button}
      color="primary"
      type="submit"
      disabled={loading}
      {...props}
    >
      {btnText}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default SubmitBtn;

/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "../components/Dashboard/index";
import ViewAllTopicQuizzes from "../pages/all-topic-quizzes";
import DifficultQuizzes from "../pages/difficult-quizzes";
import DiffQuestionFormPage from "../pages/diffquestion-form";
import Extras from "../pages/extras";
import QuizFormPage from "../pages/quiz-form";
import ViewQuizzes from "../pages/quizzes";
import QuizzesFormPage from "../pages/quizzes-form";
import ViewSchedas from "../pages/schedas";
import ViewTopics from "../pages/topics";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    show: true,
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "",
  //   icon: Person,
  //   component: ProfileFormPage,
  //   layout: "/admin",
  //   show: false
  // },
  // {
  //   path: "/service",
  //   name: "Add Service",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: ServiceFormPage,
  //   layout: "/admin",
  //   show: false
  // },
  // {
  //   path: "/services",
  //   name: "Services",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: ViewServices,
  //   layout: "/admin",
  //   show: true
  // },
  // {
  //   path: "/categories",
  //   name: "Categories",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: ViewCategories,
  //   layout: "/admin",
  //   show: true
  // },
  // {
  //   path: "/category",
  //   name: "Add Category",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: CategoryFormPage,
  //   layout: "/admin",
  //   show: false
  // },
  // {
  //   path: "/invoices",
  //   name: "Bills",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: ViewInvoices,
  //   layout: "/admin",
  //   show: true
  // },
  // {
  //   path: "/invoice-form",
  //   name: "Add Invoice",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: InvoiceFormPage,
  //   layout: "/admin",
  //   show: false
  // },
  // {
  //   path: "/invoice/print",
  //   name: "Print Invoices",
  //   rtlName: "",
  //   icon: "content_paste",
  //   component: InvoicePrint,
  //   layout: "/admin",
  //   show: false
  // },
  {
    path: "/topics",
    name: "Topics",
    rtlName: "",
    icon: "content_paste",
    component: ViewTopics,
    layout: "/admin",
    show: true,
  },
  {
    path: "/quizzes-form",
    name: "Add Quizzes",
    rtlName: "",
    icon: "content_paste",
    component: QuizzesFormPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/schedas/1/P",
    name: "Past Papers",
    rtlName: "",
    icon: "content_paste",
    component: ViewSchedas,
    layout: "/admin",
    show: true,
  },
  {
    path: "/schedas/1/PS",
    name: "Sinhala Past Papers",
    rtlName: "",
    icon: "content_paste",
    component: ViewSchedas,
    layout: "/admin",
    show: true,
  },
  {
    path: "/schedas",
    name: "Schedas",
    rtlName: "",
    icon: "content_paste",
    component: ViewSchedas,
    layout: "/admin",
    show: false,
  },
  {
    path: "/quizzes",
    name: "Quizzes",
    rtlName: "",
    icon: "content_paste",
    component: ViewQuizzes,
    layout: "/admin",
    show: false,
  },
  {
    path: "/quiz-form",
    name: "Update Quiz",
    rtlName: "",
    icon: "content_paste",
    component: QuizFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/all-topic-quizzes",
    name: "Quizzes",
    rtlName: "",
    icon: "content_paste",
    component: ViewAllTopicQuizzes,
    layout: "/admin",
    show: false,
  },
  {
    path: "/difficult-quizzes",
    name: "Difficult Quizzes",
    rtlName: "",
    icon: "content_paste",
    component: DifficultQuizzes,
    layout: "/admin",
    show: true,
  },
  {
    path: "/diffquestion-form",
    name: "Add Quizzes",
    rtlName: "",
    icon: "content_paste",
    component: DiffQuestionFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/extras",
    name: "Extras",
    rtlName: "",
    icon: "content_paste",
    component: Extras,
    layout: "/admin",
    show: true,
  },
];

export default dashboardRoutes;

import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
// import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import { api } from "../config";

interface Props {}

interface RowData {
  id: string;
  sinhala: string;
  italian: string;
  image: string;
}

const ViewTopics: React.FC<Props> = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [topicsData, setTopicsData] = React.useState([]);
  const getTopics = async () => {
    const response = await axios.get(`${api}/topics`, {
      withCredentials: true,
    });
    setTopicsData(response.data);
    setLoading(false);
    console.log(response.data);
  };
  React.useEffect(() => {
    getTopics();
  }, []);

  const addQuestionsClick = (topicId: any) => {
    history.push(`/admin/quizzes-form/${topicId}`);
  };

  const viewSchedasClick = (
    topicId: any,
    topic: string,
    qType: "N" | "S" | "P"
  ) => {
    history.push(`/admin/schedas/${topicId}/${qType}`, { topic });
  };

  return (
    <div>
      <GoBackButton />
      {/* <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Topic
      </Button> */}
      <MaterialTable
        title="Topics"
        isLoading={loading}
        columns={[
          {
            title: "Image",
            field: "image",
            render: (rowData: any) => (
              <img
                alt={rowData.italian}
                style={{ height: 80 }}
                src={rowData.image}
              />
            ),
            filtering: false,
          },
          { title: "In Italian", field: "italian" },
          { title: "In Sinhala", field: "sinhala" },
        ]}
        actions={[
          {
            icon: "add",
            tooltip: "Add Questions",
            onClick: (event, rowData: any) => {
              addQuestionsClick(rowData.id);
            },
          },
          {
            icon: "bookmark",
            tooltip: "Normals Qs Schedas",
            onClick: (event, rowData: any) => {
              viewSchedasClick(rowData.id, rowData.italian, "N");
            },
          },
          // {
          //   icon: "book",
          //   tooltip: "Past Paper Qs Schedas",
          //   onClick: (event, rowData: any) => {
          //     viewSchedasClick(rowData.id, rowData.italian, "P");
          //   },
          // },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          filtering: true,
          search: false,
        }}
        data={topicsData}
      />
    </div>
  );
};

export default ViewTopics;

import { FieldProps, getIn } from "formik";
import React from "react";
import { TextField } from "@material-ui/core";

interface Props extends FieldProps {
  customOnChange: (e: any) => void;
}

const TextFormField: React.FC<Props> = ({
  field,
  form,
  customOnChange,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <TextField
      fullWidth
      margin="normal"
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
      onChange={async (e: any) => {
        form.setFieldValue(field.name, e.target.value);
        if (typeof customOnChange !== "undefined") {
          customOnChange(e);
        }
        form.setFieldTouched(field.name);
      }}
    />
  );
};
export default TextFormField;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import DiffQuestionForm from "../components/Quiz/DiffQuestionForm";
import { api } from "../config";

interface Props {}

const DiffQuestionFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/diffquestion-form/:id");
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const isEdit = match !== null;
  let id: any = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      const result = await axios.get(`${api}/diffquestions/${id}`, {
        withCredentials: true,
      });
      setQuiz(result.data);
      setLoading(false);
    };

    if (id) {
      fetchQuestions();
    }
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div>
      <GoBackButton />
      <DiffQuestionForm quiz={quiz} />
    </div>
  );
};

export default DiffQuestionFormPage;

import React from "react";
import { useRouteMatch } from "react-router-dom";
import QuizzesForm from "../components/Quiz/QuizzesForm";
import GoBackButton from "../components/Misc/GoBackButton";

interface Props {}

const QuizzesFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/quizzes-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  if (id) {
    return (
      <div>
        <GoBackButton />
        <QuizzesForm topicIdNo={id} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <QuizzesForm />
      </div>
    );
  }
};

export default QuizzesFormPage;

import React from "react";
import SnackBar from "@material-ui/core/Snackbar";
import { useQuery, gql } from "@apollo/client";
// import { SnackbarContent } from "@material-ui/core";

interface Props {}

const PopMessage: React.FC<Props> = () => {
  const { data, loading } = useQuery(OPEN_MSG_QUERY);
  if (loading) return <div>Loading</div>;
  const {
    popMsg: { isMsgOpen, message }
  } = data;

  return (
    <SnackBar
      open={isMsgOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{message}</span>}
      style={{
        zIndex: 100000
      }}
    />
  );
};

export default PopMessage;

export const OPEN_MSG_QUERY = gql`
  query OPEN_MSG_QUERY {
    popMsg @client {
      isMsgOpen
      message
    }
  }
`;

import Button from "@material-ui/core/Button";
import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import DialogModal from "../components/Misc/DialogModal";
import GoBackButton from "../components/Misc/GoBackButton";
import QuizForm from "../components/Quiz/QuizForm";
import { api } from "../config";

interface Props {}

interface RowData {
  id: string;
  sinQ: string;
  itaQ: string;
  qNo: string;
  qType: string;
  isTrue: string;
  topicId: string;
  schedaNo: string;
}

const ViewQuizzes: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const matchAll = useRouteMatch("/admin/quizzes/:topicId/:qType/:schedaNo");
  const [editQuiz, setEditQuiz] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const ref = React.useRef(null);
  let topicId: any = undefined;
  let qType: any = undefined;
  let schedaNo: any = undefined;

  const [quizzesData, setQuizzesData] = React.useState([]);
  const getQuizzes = async () => {
    const response = await axios.get(
      `${api}/quizzes?topic=${topicId}&qType=${qType}&schedaNo=${schedaNo}&_sort=qNo:ASC`,
      {
        withCredentials: true,
      }
    );
    setQuizzesData(response.data);
    setLoading(false);
    console.log(response.data);
  };
  React.useEffect(() => {
    if (topicId && qType && schedaNo) {
      getQuizzes();
    }
  }, [topicId, qType, schedaNo]);

  const handleAddClick = () => {
    history.push(`/admin/quizzes-form`);
  };

  const handleDeleteClick = async (id: number) => {
    if (window.confirm("Are you sure to delete this Question?")) {
      await axios.delete(`${api}/quizzes/${id}`, { withCredentials: true });
      getQuizzes();
    }
  };

  React.useEffect(() => {
    if (open === false && Object.keys(editQuiz).length > 0) {
      getQuizzes();
    }
  }, [open]);

  if (matchAll) {
    //@ts-ignore
    topicId = matchAll?.params.topicId;
    //@ts-ignore
    qType = matchAll?.params.qType;
    //@ts-ignore
    schedaNo = matchAll?.params.schedaNo;
  } else {
    return <div>No url parameters</div>;
  }

  return (
    <div>
      {open && (
        <DialogModal
          title="Edit Quiz"
          content={<QuizForm quiz={editQuiz} isDialog={setOpen} />}
          open={open}
          setOpen={setOpen}
          width="lg"
        />
      )}
      <GoBackButton />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Quiz
      </Button>
      <br />
      <br />
      <MaterialTable
        tableRef={ref}
        isLoading={loading}
        title={`Scheda ${schedaNo} Quizzes`}
        columns={[
          {
            title: "True or False",
            field: "isTrue",
            filtering: false,
            render: (rowData: any) =>
              rowData.isTrue ? (
                <div
                  style={{
                    backgroundColor: "green",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  True
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  False
                </div>
              ),
          },
          { title: "Quiz No", field: "qNo" },
          { title: "Question in Italian", field: "itaQ" },
          { title: "Question in Sinhala", field: "sinQ" },
        ]}
        localization={{
          header: {
            actions: "",
          },
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Delete Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              handleDeleteClick(rowData.id);
              // editQuestion(rowData.id);
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Question",
            position: "auto",
            onClick: (event, rowData: any) => {
              const data = { ...rowData, topicId: rowData.topic.id };
              setEditQuiz(data);
              setOpen(true);
              // editQuestion(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: 0,
          exportButton: true,
          filtering: true,
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40],
        }}
        data={quizzesData}
      />
    </div>
  );
};

export default ViewQuizzes;

import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import QuizForm from "../components/Quiz/QuizForm";

interface Props {}

const QuizFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/quiz-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(GET_QUIZ_QUERY, {
    variables: {
      id,
    },
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { getQuiz } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <QuizForm quiz={getQuiz} />
      </div>
    );
  } else {
    return <div>No Data</div>;
  }
};

export default QuizFormPage;

const GET_QUIZ_QUERY = gql`
  query GET_QUIZ_QUERY($id: ID!) {
    getQuiz(id: $id) {
      id
      topicId
      schedaNo
      qType
      sinQ
      itaQ
      isTrue
    }
  }
`;

import { makeStyles, Theme, createStyles } from "@material-ui/core";

const shortCutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      [theme.breakpoints.down("md")]: {
        gridGap: 2
      },
      [theme.breakpoints.up("md")]: {
        gridGap: 10
      }
    },
    cardBody: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr"
    }
  })
);

export default shortCutStyles;

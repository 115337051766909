import { gql, useApolloClient, useMutation } from "@apollo/client";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import logo from "../assets/img/logo.jpg";
import { api } from "../config";
import FormError from "./Misc/FormComponents/FormError";
// import { SIGN_IN_QUERY } from "../../../lib/graphqlQueries";
// import { initState } from "../../../lib/initialState";
// import useStyles from "./SigninBoxStyles";
import SubmitBtn from "./Misc/FormComponents/SubmitBtn";
import TextFormField from "./Misc/FormComponents/TextFormField";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // height: "100%",
      [theme.breakpoints.up("md")]: {
        maxWidth: "50%",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      margin: "0 auto",
    },
    image: {
      backgroundImage: "url(/static/login_bg.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(5, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      paddingTop: "0px",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    heading: {
      fontSize: "1.5rem",
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        height: 200,
        width: 200,
      },
      [theme.breakpoints.up("sm")]: {
        height: 300,
        width: 300,
      },
    },
  })
);

const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

interface Props {
  // history: any;
}
const LoginForm: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles({});
  const client = useApolloClient();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  //@ts-ignore
  const onCompleted = async ({ signin }) => {
    // console.log(signin);
    await client.cache.reset();
    // console.log("logged in");
    history.push("/admin");
  };

  //@ts-ignore
  const onError = (error) => {
    console.error(error);
    setSubmitLoading(false);
    setErrMsg("Username or Password is Invalid");
    setTimeout(() => {
      setErrMsg("");
    }, 5000);
  };

  const [signinUser] = useMutation(SIGN_IN_QUERY, {
    onCompleted,
    onError,
  });

  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    try {
      setSubmitLoading(true);
      // console.log(values.username, values.password);
      // await signinUser({
      //   variables: {
      //     username: values.username,
      //     password: values.password
      //   }
      // });
      // setSubmitLoading(false);
      const loginData = {
        identifier: values.username,
        password: values.password,
      };
      const response = await axios.post(`${api}/auth/local`, loginData, {
        withCredentials: true,
      });
      onCompleted({ signin: response.data });
      console.log(response);
    } catch (err) {
      console.log("submit loading");
      setSubmitLoading(false);
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <img src={logo} alt="company logo" className={classes.logo} />
        <div className={classes.paper}>
          <Typography variant="button" className={classes.heading}>
            Italiy Driving Licence Application
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={schema}
          >
            {({ isValid }) => (
              <Form>
                <Field
                  name="username"
                  label="Username"
                  component={TextFormField}
                  fullWidth
                  variant="outlined"
                  autoFocus
                />
                <Field
                  name="password"
                  label="Password"
                  type="password"
                  component={TextFormField}
                  fullWidth
                  variant="outlined"
                />
                <FormError message={errMsg} style={{ textAlign: "center" }} />
                <SubmitBtn
                  loading={submitLoading}
                  btnText="SIGN IN"
                  fullWidth
                  disabled={!isValid || submitLoading}
                />
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
};
export default LoginForm;

const SIGN_IN_QUERY = gql`
  mutation SIGN_IN_QUERY($username: String!, $password: String!) {
    signin(username: $username, password: $password) {
      username
      password
    }
  }
`;

import { gql, useApolloClient } from "@apollo/client";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import Dropdown, { Item } from "../Misc/FormComponents/Dropdown";
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField";
import TextFormField from "../Misc/FormComponents/TextFormField";
import Loading from "../Misc/Loading";
import QuizzesSubForm from "./QuizzesSubForm";
import quizFormStyles from "./styles/quizFormStyles";

yup.addMethod(
  yup.array,
  "unique",
  function (message: any, mapper = (a: any) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  }
);

const schema = yup.object({
  topicId: yup.string().when("qType", {
    is: "N",
    then: yup.string().required("Topic is required"),
    otherwise: yup.string(),
  }),
  schedaNo: yup.string().required("Scheda No is required"),
  quizzes: yup
    .array()
    .of(
      yup.object().shape({
        qNo: yup.string().required("Quiz No is required"),
        sinQ: yup.string().required("Sinhala Question is required"),
        itaQ: yup.string().required("Italian Question is required"),
        isTrue: yup.boolean(),
      })
    )
    .required("Quizzes are required")
    .min(1)
    .typeError("At least 1 quiz must be selected"),
});

type TopicFieldProps = {
  topicIdNo?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  topics: any;
};

const TopicField: React.FC<TopicFieldProps> = ({
  topics,
  setFieldValue,
  topicIdNo,
}) => {
  React.useEffect(() => {
    if (topicIdNo) {
      handleTopicChange(topicIdNo, setFieldValue);
    }
  }, []);

  const handleTopicChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue("topicId", parseInt(value));
  };

  return (
    <Field
      label="Topics"
      name="topicId"
      component={ReactSelectFormField}
      options={topics}
      placeholder="Topics"
    />
  );
};

type Props = {
  topicIdNo?: string;
};

type SubmitType = {
  topicId: string;
  schedaNo: string;
  qType: string;
  quizzes: {
    qNo: string;
    sinQ: string;
    itaQ: string;
    isTrue: boolean;
    image: string;
  }[];
};

export const questionTypes: Item[] = [
  // I have assigned the first element as the default value in formik initial values
  {
    value: "N",
    description: "Normal Questions",
  },
  // {
  //   value: "S",
  //   description: "Speculative Questions",
  // },
  {
    value: "P",
    description: "Past Papers Questions",
  },
  {
    value: "PS",
    description: "Sinhala Past Papers Questions",
  },
];

const QuizzesForm: React.FC<Props> = ({ topicIdNo }) => {
  const history = useHistory();
  const client = useApolloClient();
  const classes = quizFormStyles();

  const [questionType, setQuestionType] = React.useState("N");

  const [loading, setLoading] = React.useState(true);
  const [topicsData, setTopicsData] = React.useState([]);
  const getTopics = async () => {
    const response = await axios.get(`${api}/topics`, {
      withCredentials: true,
    });
    setTopicsData(response.data);
    setLoading(false);
    console.log(response.data);
  };
  React.useEffect(() => {
    getTopics();
  }, []);
  // const { loading, data, error } = useQuery(GET_TOPICS_QUERY);

  const handleSubmit = async ({
    topicId,
    schedaNo,
    qType,
    quizzes,
  }: SubmitType) => {
    const data = quizzes.map((quiz) => ({
      topic: parseInt(topicId),
      schedaNo: parseInt(schedaNo),
      qType,
      qNo: parseInt(quiz.qNo),
      sinQ: quiz.sinQ,
      itaQ: quiz.itaQ,
      isTrue: quiz.isTrue,
      image: quiz.image,
    }));

    try {
      setQuestionType(qType);
      const response = await axios.post(
        `${api}/quizzes`,
        { quizzes: data },
        {
          withCredentials: true,
        }
      );

      history.push(`/admin/quizzes/${topicId}/${qType}/${schedaNo}`, {
        topicId,
        schedaNo,
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  const topics = topicsData.map(
    (topic: { italian: string; __typename: string; id: number }) => ({
      label: topic.italian,
      value: topic.id,
    })
  );

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                topicId: "1",
                schedaNo: "",
                qType: questionTypes[0].value,
                quizzes: [
                  { qNo: "", sinQ: "", itaQ: "", isTrue: false, image: "" },
                ],
              }}
              validationSchema={schema}
              validateOnChange={false}
            >
              {({ values, errors, isValid, validateForm, setFieldValue }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <h3 className={classes.heading}>Add Quizzes</h3>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 1fr",
                      alignItems: "center",
                      gridGap: 10,
                    }}
                  >
                    {values.qType === "N" && (
                      <TopicField
                        topics={topics}
                        topicIdNo={topicIdNo}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <Field
                      label="Scheda No"
                      name="schedaNo"
                      component={TextFormField}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <Field
                    name="qType"
                    component={Dropdown}
                    items={questionTypes}
                  />
                  <br />
                  <br />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <QuizzesSubForm
                    quizzes={values.quizzes}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                  />
                  <div>
                    {typeof errors.quizzes !== "undefined" &&
                    typeof errors.quizzes === "string" ? (
                      <div style={{ color: "red" }}>
                        {JSON.stringify(errors.quizzes, null, 2)?.replace(
                          /"/g,
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default QuizzesForm;

const CREATE_QUIZZES_MUTATION = gql`
  mutation CREATE_QUIZZES_MUTATION($data: QuizzesInput!) {
    createQuizzes(input: $data)
  }
`;

const GET_TOPICS_QUERY = gql`
  query GET_TOPICS_QUERY {
    getTopics {
      data {
        id
        italian
      }
    }
  }
`;

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

type Props = {
  title: string;
  content: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
};

const DialogModal: React.FC<Props> = ({
  title,
  content,
  open = false,
  setOpen,
  width = "sm",
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={width as DialogProps["maxWidth"]}
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Form</DialogContentText>
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default DialogModal;

import { gql } from "@apollo/client";
import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import Loading from "../components/Misc/Loading";
import { api } from "../config";

interface Props {}

const Login: React.FC<Props> = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  const getMyData = async () => {
    try {
      await axios.get(`${api}/users/me`, {
        withCredentials: true,
      });
      history.push("/admin");
    } catch (err) {
      // history.push("/admin");
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getMyData();
  }, []);

  if (loading) return <Loading fullHeight={true} />;
  // if (error) {
  //   console.log(error);
  //   return <div>Error</div>;
  // }

  // const { me } = data;

  // if (me !== null) {
  //   history.push("/");
  // }

  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default Login;

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      username
    }
  }
`;
